<template>
  <div>
    <!-- Table Families Card -->
 
    <b-card
      no-body
      class="mb-0"
    >
      <div class="app-action">
        <b-row>
          <b-col
            cols="3"
            md="3"
            class="
               m-10
              "
          >
            <label>خيارات العرض</label>
            <v-select
              v-model="today"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="todayOptions"
              
              :clearable="true"
              
            />
            <!-- <label>SortBy</label>
                <v-select
                style="width:200px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  v-model="sort"
                  multiple
                  :clearable="true"
                   />
             -->
          </b-col>
          <b-col
            cols="3"
            md="3"
            class="
                m-10
              "
          >
            <label>عدد عناصر </label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
             
            />
            <!-- <label>SortBy</label>
                <v-select
                style="width:200px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  v-model="sort"
                  multiple
                  :clearable="true"
                   />
             -->
          </b-col>
          
        
        </b-row>
      </div>

      <!-- Update Mail Folder Dropdown -->

      <b-table
        ref="refFamiliesListTable"
        :items="fetchArchive"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
      >
        <!-- Column: editfield -->

        <!-- Column: Actions -->
        <template #cell(action_id)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item
             :to="{ name: 'transactions-archive-id', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>
            <b-dropdown-item
             :to="{ name: 'edit-transactions-archive-id', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="role !== 'accountant'" @click="deleteArchive(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item>
          
     
          </b-dropdown>
          
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import formValidation from '@core/comp-functions/forms/form-validation'
import archiveStoreModule from './archiveStoreModule.js'
import useRequestItemList from './archiveList'
import store from '@/store'

export default {
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const Archive_APP_STORE_MODULE_NAME = 'app-Archive'

    // Register module
    if (!store.hasModule(Archive_APP_STORE_MODULE_NAME)) { store.registerModule(Archive_APP_STORE_MODULE_NAME, archiveStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Archive_APP_STORE_MODULE_NAME)) { store.unregisterModule(Archive_APP_STORE_MODULE_NAME) }
    })
    // Mail Selection
    // ------------------------------------------------
    const Form = ref({
      name: '',
      phone: '',
      email: '',
      location: '',
      notes: '',
    })
    const onSubmit = () => {
      //console.log(Form)

      store.dispatch('app-Archive/CreateArchive', Form.value).then(response => {
        Form.value.name = ''
        Form.value.phone = ''
        Form.value.notes = ''
        Form.value.email = ''
        Form.value.location = ''
        resetForm()
        refetchData()
        Vue.swal({
          title: '',
          text: 'تمت أضافة  بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    const todayOptions=[{
      label:'دفتر اليوم',
      value:1
    }]
    const {
      tableColumns,
      today,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchArchive,

      selectedItem,
      
      // Extra Filters
    } = useRequestItemList()

    return {
      todayOptions,
   
      onSubmit,
      today,
      refFormObserver,
      resetForm,
      Form,
      getValidationState,
      // Sidebar

      search: null,
      fetchArchive,

      filterOn: [],
      searchTerm: '',
      tableColumns,
      selectedItem,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    }
  },
  data() {
    return {
      role:''
    }},

created() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    this.role = userData.roles[0].name;
  //  console.log('userData',userData)
  //  console.log('role',this.role)
},
  methods: {
    deleteArchive(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/finance/transactions/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            this.refetchData();
            this.$swal({
        icon: "warning",
        // title: "تم الحذف  بنجاح",
        title:res.data.message,
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
          });
        }
      });
    },


  },

  components: {

    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },

}
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.app-action {
  margin: 18px;
}
.d-block.invalid-feedback {
    color: red;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
